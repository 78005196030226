<template>
  <section class="pr-5 pl-5 md:pr-115 md:pl-115 pb-5 md:pb-106">
    <div class="top-header w-full pt-20 md:pt-103px md:pb-24 pb-16 bg-cover">
      <h3
        data-aos="fade-up"
        data-aos-duration="1000"
        class="
          font-bold
          text-4xl
          md:text-54px
          text-black
          leading-tight
          md:leading-80px
          mx-auto
          md:max-w-50rem
          text-center
        "
      >
        Connecting
        <span class="inline-block text-primarygreen relative"
          >African Commerce
        </span>
        to the Rest of the World
      </h3>
      <p
        data-aos="fade-up"
        data-aos-duration="2000"
        class="
          leading-30px
          text-pelpaygray200 text-xl
          md:text-2xl
          font-normal
          text-center
          max-w-2xl
          mx-auto
          mt-6
        "
      >
        Pelpay’s vision is to make it seamless for Africans to build businesses
        that can transact with anyone across the world
      </p>
    </div>
    <div
      data-aos="fade-up"
      data-aos-duration="2000"
      class="
        imageflex
        w-full
        flex
        md:flex-row
        flex-col
        space-y-4
        md:space-x-10
        lg:space-x-20
        justify-between
      "
    >
      <div class="">
        <img class="" src="../../assets/images/conn1.png" alt="" />
      </div>
      <div class="">
        <img class="" src="../../assets/images/conn2.png" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
